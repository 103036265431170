import dayjs from 'dayjs';
import Cookies from 'js-cookie';

const emptyConsentResponse = { consent: null, consent_timestamp: null };

export const getConsentData = (): { consent: boolean | null; consent_timestamp: string | null } => {
	if (typeof window === 'undefined') return emptyConsentResponse;

	const savedConsent = Cookies.get('cookie_consent');

	if (!savedConsent) {
		return emptyConsentResponse;
	}

	const { consent_given, timestamp } = JSON.parse(savedConsent);

	//Check that values for consent from local storage are valid
	const consent = typeof consent_given === 'boolean' ? consent_given : null;
	const consent_timestamp = dayjs(timestamp).isValid() ? timestamp : null;

	return { consent, consent_timestamp };
};
