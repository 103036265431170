import { FC } from 'react';

import { Link } from '@vakantiesnl/components/src/atoms/Link';

import VakantiesLogoSvg from './assets/vakantiesLogo.svg';
import ZooverLogoSvg from './assets/zooverLogo.svg';
import { useStyles } from './BrandLogo.styles';

export type BrandLogoProps = {
	/** Custom classnames */
	className?: string;
	/** Link for the logo, defaults to "/" */
	href?: string;
	/** Custom title for the anchor */
	title?: string;
	/** Onclick handler */
	onClick?: VoidFunction;
};
export const BrandLogo: FC<BrandLogoProps> = ({
	className,
	onClick,
	href = '/',
	title = process.env.NEXT_PUBLIC_BRAND === 'ZVRNL' ? 'Zoover' : 'Vakanties.nl',
}) => {
	const { classes, cx } = useStyles();

	return (
		<Link
			href={href}
			className={cx(classes.logo, className)}
			rel=""
			onClick={onClick}
			title={title}
			aria-label={title}
		>
			{process.env.NEXT_PUBLIC_BRAND === 'ZVRNL' ? (
				<ZooverLogoSvg alt="zoover-logo" />
			) : (
				<VakantiesLogoSvg alt="vakanties-logo" />
			)}
		</Link>
	);
};
