import { ChangeEvent, FC, useCallback } from 'react';

import { Icon } from '@vakantiesnl/components/src/__LEGACY__/atoms/Icon';
import classNames from 'classnames';

import styles from './CheckBox.module.css';

type Props = {
	id: string;
	handleChange?: (change: boolean, ...args: unknown[]) => void;
	checked?: boolean;
	disabled?: boolean;
	title?: string;
	variant?: 'large' | 'small';
	className?: string;
	dataCy?: string;
};

const CheckBox: FC<Props> = ({ id, handleChange, checked, disabled, title, variant, className, dataCy }) => {
	const classVariant = variant === 'large' ? styles.large : styles.small;
	const onChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>): void => {
			if (handleChange) {
				handleChange(event.target.checked);
			}
		},
		[handleChange],
	);

	return (
		<div
			className={classNames(
				styles.checkboxContainer,
				classVariant,
				title && styles.labeled,
				className,
				disabled && styles.disabled,
			)}
			data-cy={dataCy}
		>
			<input
				type="checkbox"
				id={id}
				className={styles.checkbox}
				disabled={disabled}
				checked={checked}
				onChange={onChange}
			/>
			<label htmlFor={id} className={styles.checkboxLabel}>
				<Icon className={styles.checkboxIcon} type="vaknlCheck" />
				<span className={styles.labelText}>{title}</span>
			</label>
		</div>
	);
};

export { CheckBox };
