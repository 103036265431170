import { FC, useEffect, useMemo } from 'react';

import { useMicroCopyContext } from '@vakantiesnl/services/src/context/microCopyContext';
import { useApplicationStore } from '@vakantiesnl/services/src/stores/applicationStore';
import { normalizeCanonicalToOrigin } from '@vakantiesnl/services/src/util/normalizeCanonicalToOrigin';
import { Seo } from '@vakantiesnl/types';
import Head from 'next/head';
import Script from 'next/script';

type Props = {
	seo: Seo;
};

const Meta: FC<Props> = ({ seo }) => {
	const { staticCanonicalOrigin: origin } = useApplicationStore((s) => s);
	const microCopies = useMicroCopyContext();

	const trigger = useMemo((): { __html: string } => {
		return {
			__html: `
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				page_info: {
					page_type: '${seo.gtmPageType}',
					market: 'nl',
					language: 'nl-NL',
					page_title: "${seo.title}"
				}
			});
			window.dataLayer.push({
				event: 'dmp.js',
			});
		`,
		};
	}, [seo]);

	useEffect(() => {
		import('@vakantiesnl/services/src/gtm/common').then(({ track, onWindowFullyLoaded }) =>
			track(onWindowFullyLoaded()),
		);
	}, []);

	return (
		<Head>
			<title>{seo.title}</title>
			<meta name="description" content={seo.description} />
			<meta property="og:locale" content="nl-NL" />
			<meta property="og:type" content={seo.pageType} />
			<meta property="og:title" content={seo.title} />
			<meta property="og:description" content={seo.description} />
			<meta property="og:url" content={normalizeCanonicalToOrigin(seo.canonical, origin)} />
			<meta property="og:ttl" content="345600" />
			{seo.image && <meta property="og:image" content={`${seo.image}?w=1200&h=1200`} />}
			<meta property="og:site_name" content={microCopies['siteTitle'] || seo.title} />
			<meta name="robots" content={seo.robots} />
			<link rel="canonical" href={`${normalizeCanonicalToOrigin(seo.canonical, origin)}`} key="canonical" />
			<meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=2" />

			{seo.preload &&
				seo.preload.map((item, key) => (
					<link
						key={key}
						rel={item.rel}
						href={item.href}
						as={item.as}
						crossOrigin={item.crossOrigin}
						type={item.type}
					/>
				))}

			<Script dangerouslySetInnerHTML={trigger} />
		</Head>
	);
};

export default Meta;
