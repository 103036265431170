import { FC, memo, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { Button } from '@vakantiesnl/components/src/__LEGACY__/atoms/Button';

import useStyles from './Collapse.style';

type Props = {
	introductionText?: string;
	introductionChildren?: ReactNode;
	readMore: string;
	readLess: string;
	children?: ReactNode;
	withContainer?: boolean;
	alignLeft?: boolean;
	linkStyle?: boolean;
	onCollapse?: (isCollapsed: boolean) => void;
	customChildrenClass?: string;
	customToggleClass?: string;
	buttonOnTop?: boolean;
	closeButton?: boolean;
};

type ButtonProps = {
	isCollapsed: boolean;
	readMore: string;
	readLess: string;
	linkStyle?: boolean;
	customToggleClass?: string;
	closeButton?: boolean;
	alignLeft?: boolean;
	onClick: () => void;
};

const ToggleButton: FC<ButtonProps> = ({
	isCollapsed,
	readMore,
	readLess,
	onClick,
	linkStyle,
	customToggleClass,
	closeButton,
	alignLeft,
}) => {
	const { classes, cx } = useStyles();
	return (
		<div
			data-collapsed={isCollapsed ? 'collapsed' : ''}
			className={cx(classes.toggleButtonContainer, alignLeft && classes.toggleButtonContainerLeft)}
			onClick={onClick}
		>
			{!linkStyle && (
				<Button
					onClick={onClick}
					title={isCollapsed ? readMore : readLess}
					iconRight={isCollapsed ? 'vaknlChevronDown' : closeButton ? 'vaknlExit' : 'vaknlChevronUp'}
					variant="link"
					className={customToggleClass}
				/>
			)}

			{linkStyle && (
				<button onClick={onClick} className={classes.toggleLink}>
					{isCollapsed ? readMore : readLess}
				</button>
			)}
		</div>
	);
};

const Collapse: FC<Props> = ({
	introductionText,
	children,
	readMore,
	readLess,
	withContainer,
	introductionChildren,
	alignLeft,
	linkStyle,
	onCollapse,
	customChildrenClass,
	customToggleClass,
	closeButton,
}) => {
	const { classes, cx } = useStyles();
	const [height, setHeight] = useState<number | null | 'auto'>('auto');
	const [tempHeight, setTempHeight] = useState<number | null | 'auto'>(null);
	const drawerRef = useRef<null | HTMLDivElement>(null);
	useEffect(() => {
		if (tempHeight === null && drawerRef.current) {
			setTempHeight(drawerRef.current?.getBoundingClientRect().height || 'auto');
			setHeight(0);
			onCollapse && onCollapse(height === 0);
		}
	}, [tempHeight, setTempHeight, setHeight, onCollapse, height]);
	const isCollapsed: boolean = height === 0;

	const containerClass = withContainer ? classes.container : '';

	const onClick = useCallback((): void => {
		if (tempHeight === null) return;
		setHeight(isCollapsed ? tempHeight : 0);
		onCollapse && onCollapse(isCollapsed);
	}, [isCollapsed, setHeight, tempHeight, onCollapse]);
	const getStyle = useCallback(() => (typeof height === 'number' ? { maxHeight: height } : {}), [height]);
	const flexDirection = alignLeft ? classes.alignStart : classes.alignCenter;

	return (
		<div className={cx(containerClass, flexDirection, !isCollapsed && classes.expanded)}>
			{introductionText && <p className={classes.intro}>{introductionText}</p>}
			{introductionChildren}

			{!linkStyle && (
				<ToggleButton
					readLess={readLess}
					readMore={readMore}
					onClick={onClick}
					isCollapsed={isCollapsed}
					customToggleClass={customToggleClass}
					closeButton={closeButton}
				/>
			)}
			<div
				className={cx(classes.children, customChildrenClass && customChildrenClass)}
				ref={drawerRef}
				style={getStyle()}
			>
				{children}
			</div>

			{linkStyle && (
				<ToggleButton
					readLess={readLess}
					readMore={readMore}
					onClick={onClick}
					isCollapsed={isCollapsed}
					linkStyle
					alignLeft={alignLeft}
				/>
			)}
		</div>
	);
};

const memoizedCollapse = memo(Collapse);

export { memoizedCollapse as Collapse };
