import { getFromBreakpoint } from '@vakantiesnl/components/src/styles/breakpoints';
import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useStyles = makeStyles()((theme) => ({
	headerContainer: {
		zIndex: theme.zIndex.raised,
		boxShadow: theme.navbar.elevation,
		marginBottom: theme.spacing.small,

		[getFromBreakpoint('lg')]: {
			marginBottom: theme.spacing.medium,
		},
	},
	brandLogo: {
		padding: `${theme.spacing.medium} 0`,
	},
	vaknlLogo: {
		'& svg': {
			width: '175px',
			height: '40px',
			border: 'none',
			verticalAlign: 'sub',
		},
	},
	headerContent: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
}));
