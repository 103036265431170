import { FC, PropsWithChildren, useMemo } from 'react';

import { NextComponentType } from 'next';

import { BookingHeader } from '@vakantiesnl/components/src/molecules/BookingHeader';
import { GTM, PageType, Seo } from '@vakantiesnl/types';
import dynamic from 'next/dynamic';

import Meta from '../../../blocks/Meta';

const ContentfulButton = dynamic(import('../../../blocks/layout/ContentfulButton'), { ssr: false });

export type LayoutProps = {
	/** Header component to render in the layout */
	Header: NextComponentType<
		Record<string, unknown>,
		Record<string, unknown>,
		{ hideMenu?: false; pageType: GTM.GTMPageType }
	>;
	/** Footer component to render in the layout */
	Footer: NextComponentType<Record<string, unknown>, Record<string, unknown>, { pageType: GTM.GTMPageType }>;
	/** SEO object for the page */
	seo: Seo;
	/** Modal component to render in the layout */
	Modal?: NextComponentType<Record<string, unknown>>;
	/** Contentful item ID to show the Contentful preview button */
	contentfulId?: string;
	cookieConsent?: string;
};

const Layout: FC<PropsWithChildren<LayoutProps>> = ({
	children,
	Header,
	Footer,
	Modal,
	contentfulId,
	seo,
	cookieConsent,
}) => {
	const isBooking = useMemo(() => seo.pageType === PageType.booking, [seo.pageType]);

	return (
		<>
			{seo && <Meta seo={seo} />}
			{isBooking ? <BookingHeader /> : <Header pageType={seo.gtmPageType} />}
			{children}
			<Footer pageType={seo.gtmPageType} />
			{Modal && !cookieConsent && <Modal />}
			{contentfulId && <ContentfulButton id={contentfulId} />}
		</>
	);
};

export default Layout;
