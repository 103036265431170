// Input could be any object tyope
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type InputValue = Record<string, any>;

const snakeCase = (e: string): string => {
	const matchedString = e.match(/([A-Z])/g);

	if (matchedString) {
		return matchedString
			.reduce((str: string, c: string) => str.replace(new RegExp(c), '_' + c.toLowerCase()), e)
			.substring(e.slice(0, 1).match(/([A-Z])/g) ? 1 : 0);
	}

	return e;
};

const parse = (obj: InputValue, fn: typeof snakeCaseConverter): InputValue | null => {
	if (!obj) return null;

	const keys = Object.keys(obj);
	const values = Object.values(obj);

	keys.forEach((key, i) => {
		if (key && Array.isArray(values[i])) {
			fn(key, obj);
			values[i].map((arrKey: InputValue) => parse(arrKey, fn));
		} else if (key && typeof values[i] === 'object') {
			fn(key, obj);
			parse(values[i], fn);
		} else {
			fn(key, obj);
		}
	});

	return obj;
};

const snakeCaseConverter = (oldKey: string, obj: InputValue): InputValue => {
	const newKey = snakeCase(oldKey);

	if (oldKey !== newKey) {
		Object.defineProperty(obj, newKey, Object.getOwnPropertyDescriptor(obj, oldKey) as PropertyDescriptor);
		delete (obj as Record<string, unknown>)[oldKey];
	}
	return obj;
};

/** Utility function to convert large chunks of camelCase API responses into snake_case */
export const toSnakeCase = (input: InputValue): InputValue | null => {
	// JSON.parse ( JSON.stringify ( ... ) ) applied here instead of lodash cloneDeep
	// to avoid "Circular reference" value when same reference to an object is kept by cloneDeep
	// it has a lower performance however and potentially can be improved
	return parse(JSON.parse(JSON.stringify(input)), snakeCaseConverter);
};
