import { deprecatedMakeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

const useStyles = deprecatedMakeStyles()((theme) => ({
	bottomContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: theme.spacing(1.75),

		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
		},

		'& label span': {
			paddingRight: 0,
			fontWeight: theme.typography.fontWeightMedium as number,
		},
	},
	heading: {
		color: theme.palette.primary.main,
		fontWeight: theme.typography.fontWeightBold as number,
	},
	checkboxDescription: {
		lineHeight: 1.54,
		fontSize: '0.875rem',
		maxWidth: '400px',
		marginLeft: theme.spacing(1),
		marginTop: theme.spacing(0.25),
		marginBottom: theme.spacing(3),

		[theme.breakpoints.up('md')]: {
			marginLeft: theme.spacing(4),
		},
	},
	buttonContainer: {
		width: '100%',

		[theme.breakpoints.up('md')]: {
			width: 'auto',
		},

		'& button': {
			width: '100%',
			justifyContent: 'center',
		},
	},
}));

export default useStyles;
