import { alpha } from '@mui/material/styles';
import { deprecatedMakeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

const useStyles = deprecatedMakeStyles<void, 'alignCenter' | 'alignStart' | 'expanded'>()((theme, _, classes) => ({
	containerStory: {
		display: 'flex',
		fontFamily: theme.typography.fontFamily,
		background: 'black',
		padding: `${theme.spacing(2.5, 2.5)} !important`,
		fontSize: theme.typography.body1.toString(),
	},
	container: {
		display: 'flex',
		padding: theme.spacing(1.75),
		flexDirection: 'row',
		flex: 1,
		border: theme.borders?.default,
		borderRadius: '2px',
		[`&.${classes.alignCenter}`]: {
			alignItems: 'center',
			justifyContent: 'space-between',
			flexWrap: 'wrap',
		},
		[`&.${classes.alignStart}`]: {
			justifyContent: 'flex-start',
		},
		[`&.${classes.expanded}`]: {
			flexWrap: 'wrap',
		},
	},
	alignCenter: {},
	alignStart: {},
	expanded: {},
	toggleButtonContainer: {
		cursor: 'pointer',
		justifyContent: 'flex-end',
		display: 'flex',
		position: 'relative',
		marginLeft: 'auto',
		'&:not([data-collapsed="collapsed"])': {
			order: 99,
		},
	},
	toggleButtonContainerLeft: {
		justifyContent: 'flex-start',
	},
	toggleLink: {
		position: 'relative',
		cursor: 'pointer',
		zIndex: 1,
		color: theme.palette.common.black,
		margin: theme.spacing(0.5, 0, 0, 4),
		padding: 0,
		fontFamily: theme.typography.fontFamily,
		fontSize: theme.typography.body2.fontSize,
		lineHeight: 1.15,
		textTransform: 'none',
		background: 'none',
		border: 'none',
		overflow: 'visible',
		'&::after': {
			content: '""',
			position: 'absolute',
			bottom: 1,
			zIndex: -1,
			display: 'block',
			width: '100%',
			borderBottom: `4px solid ${alpha(theme.palette.primary.main, 0.5)}`,
			transition: 'border-bottom 0.2s',
		},
		'&:hover::after': {
			borderBottom: `4px solid ${theme.palette.primary.main}`,
		},
	},
	intro: {
		display: 'flex',
	},
	children: {
		transition: 'max-height 0.15s',
		overflow: 'hidden',
	},
}));

export default useStyles;
