import { startCase } from 'lodash';

export type GeoRaw = {
	country?: string;
	region?: string;
	city?: string;
};

export type Geo = {
	country_name?: string;
	region_name?: string;
	city_name?: string;
};

/** TODO: refactor this util
 * we might need to revisit this util because it's manipulating a slug to a named geo
 * And this is not reliable since could be different than the actual geo
 */
export const useMapGeo = ({ country, region, city }: GeoRaw): Geo => ({
	...(country && { country_name: startCase(country) }),
	...(region && { region_name: startCase(region) }),
	...(city && { city_name: startCase(city) }),
});
