import { FC, ReactNode } from 'react';

import Cookie from '@vakantiesnl/components/src/__LEGACY__/blocks/cookie';
import { useContentfulIdContext } from '@vakantiesnl/services/src/context/useContentfulIdContext';
import { useSeoContext } from '@vakantiesnl/services/src/context/useSeoContext';
import { Seo } from '@vakantiesnl/types';

import Footer from '../blocks/footer/Footer';
import Header from '../blocks/header/Header';
import Layout from '../components/templates/Layout/Layout';
import { LayoutRenderProps } from '../pages/_app';

export type Props = LayoutRenderProps & {
	children: ReactNode;
	pageProps: {
		seo: Seo;
		errorCode?: 404;
		contentfulId?: string;
	};
};

const DefaultLayout: FC<Props> = ({ children, pageProps: { errorCode }, cookieConsent }) => {
	const { seo } = useSeoContext();
	const { contentfulId } = useContentfulIdContext();

	return errorCode ? (
		<>{children}</>
	) : (
		<Layout
			seo={seo}
			Header={Header}
			Footer={Footer}
			Modal={!cookieConsent ? Cookie : undefined}
			contentfulId={contentfulId}
		>
			{children}
		</Layout>
	);
};

export default DefaultLayout;
