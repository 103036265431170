import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useStyles = makeStyles()((theme) => ({
	logo: {
		display: 'flex',
		alignItems: 'center',

		'& svg': {
			width: '97px',
			height: '24px',
			border: 'none',
			verticalAlign: 'sub',
			color: theme.primary.color.default,
		},
	},
}));
