import { FC } from 'react';

import { Document } from '@contentful/rich-text-types';
import { Button } from '@vakantiesnl/components/src/__LEGACY__/atoms/Button';
import { CheckBox } from '@vakantiesnl/components/src/__LEGACY__/atoms/CheckBox';
import { Collapse } from '@vakantiesnl/components/src/__LEGACY__/atoms/Collapse';
import { Heading } from '@vakantiesnl/components/src/__LEGACY__/atoms/Heading';
import { ConsentWrapper } from '@vakantiesnl/components/src/__LEGACY__/organisms/ConsentWrapper';
import { RichText } from '@vakantiesnl/components/src/molecules/RichText';
import { MicroCopy } from '@vakantiesnl/types';

import useStyles from './Cookie.style';

export type CookieProps = {
	richContent?: Document;
	microCopies: MicroCopy;
	consent?: boolean;
	handleConsentChange?: (status: boolean) => void;
	handleSubmit?: () => void;
};

export const Cookie: FC<CookieProps> = ({ richContent, microCopies, consent, handleConsentChange, handleSubmit }) => {
	const { classes: styles } = useStyles();

	return (
		<ConsentWrapper isOpen={true}>
			<Heading className={styles.heading} headingLevel="h3">
				{microCopies['cookiebanner.title']}
			</Heading>
			<Collapse
				introductionText={microCopies['cookiebanner.subtitle']}
				readMore={microCopies['readMore']}
				readLess={microCopies['readLess']}
				withContainer={true}
			>
				{richContent && <RichText document={richContent} />}
			</Collapse>
			<div className={styles.bottomContainer}>
				<div>
					<CheckBox
						variant="small"
						handleChange={handleConsentChange}
						checked={consent}
						id="cookieconsent"
						title={microCopies['cookieCheckTitle']}
					/>
					<p className={styles.checkboxDescription}>{microCopies['cookieCheckDescription']}</p>
				</div>
				<div className={styles.buttonContainer}>
					<Button title={microCopies['cookiebanner.save']} onClick={handleSubmit} variant="primary" />
				</div>
			</div>
		</ConsentWrapper>
	);
};
