import { Entity } from '../search';

type Props = {
	countrySlugs?: string[];
	regionSlugs?: string[];
	citySlugs?: string[];
	themes?: string[];
};

/* Transforms filter values to entity_id & entity_level for datalayer events*/
export const useSearchPageEntity = ({ countrySlugs, regionSlugs, citySlugs, themes }: Props): Entity | null => {
	if (citySlugs?.length) {
		return { entity_id: citySlugs[0], entity_level: 'destination' };
	} else if (regionSlugs?.length) {
		return { entity_id: regionSlugs[0], entity_level: 'destination' };
	} else if (countrySlugs?.length) {
		return { entity_id: countrySlugs[0], entity_level: 'destination' };
	} else if (themes?.length) {
		return { entity_id: themes[0], entity_level: 'theme' };
	} else return null;
};
