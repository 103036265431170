import { GTM, Search } from '@vakantiesnl/types';

import { isPackageOffer } from '../../util';

export const mapOffer = (offer?: Search.OfferItem | Search.AccommodationOnlyOffer | null): GTM.GTMEventV2['value'] => {
	return {
		price: {
			currency: 'EUR',
			price_total: offer?.priceTotal,
			price_per_adult: offer?.pricePerAdult,
			price_per_child: offer?.pricePerChild,
			price_per_baby: offer?.pricePerBaby,
			orginal_price_per_adult: isPackageOffer(offer) ? offer?.originalPricePerAdult : null,
		},
		duration_days: offer?.durationDays,
		duration_nights: offer?.durationNights,
		extras: {
			has_transfer: isPackageOffer(offer) ? offer?.rooms.some((room) => room.hasTransfer) || false : null,
		},
		mealplan: isPackageOffer(offer) ? offer?.rooms[0].board.code : null,
		rooms: isPackageOffer(offer) ? offer?.rooms.map((room) => room.codes) : null,
		inbound: isPackageOffer(offer) ? offer?.inbound : null,
		outbound: isPackageOffer(offer) ? offer?.outbound : null,
	};
};
