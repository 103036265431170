import { FC } from 'react';

import { BrandLogo } from '@vakantiesnl/components/src/atoms/BrandLogo';
import { Content } from '@vakantiesnl/components/src/atoms/Content';
import { useHeaderContext } from '@vakantiesnl/services/src/context/headerContext';
import { HeaderNavBlock } from '@vakantiesnl/types';

import { useStyles } from './BookingHeader.styles';
import { TrustPilot } from '../../atoms/TrustPilot';
import { EmptyHeaderTopBar } from '../HeaderTopBar/EmptyHeaderTopBar';

export const BookingHeader: FC = () => {
	const { classes, cx } = useStyles();
	const headerContext = useHeaderContext<HeaderNavBlock>();

	return headerContext && !!Object.keys(headerContext).length ? (
		<header className={classes.headerContainer}>
			<EmptyHeaderTopBar />
			<Content className={classes.headerContent}>
				<BrandLogo
					className={cx(classes.brandLogo, {
						[classes.vaknlLogo]: process.env.NEXT_PUBLIC_BRAND === 'VAKNL',
					})}
				/>
				<div>
					<TrustPilot />
				</div>
			</Content>
		</header>
	) : null;
};
