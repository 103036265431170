import { alpha } from '@mui/material/styles';
import { deprecatedMakeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

const useStyles = deprecatedMakeStyles()((theme) => ({
	container: {
		position: 'fixed',
		bottom: theme.spacing(2),
		width: '100%',
		zIndex: theme.zIndex.modal,
	},
	content: {
		padding: theme.spacing(0, 2),
		backgroundColor: theme.palette.common.white,
		borderRadius: '16px',
		maxHeight: 'calc(100vh - 110px)',
		overflowY: 'scroll',
		boxShadow: `10px 10px 20px 0 ${alpha(theme.palette.common.white, 0.2)}, -10px -10px 20px 0 ${alpha(
			theme.palette.common.white,
			0.2,
		)}`,

		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(3, 5, 3),
		},
	},
}));

export default useStyles;
