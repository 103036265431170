import { FC, ReactNode } from 'react';

import { Content } from '@vakantiesnl/components/src/__LEGACY__/atoms/Content';

import useStyles from './ConsentWrapper.style';

export interface ConsentWrapperProps {
	/** Children to render in the modal */
	children: ReactNode;
	/** Boolean indicating whether the modal should render */
	isOpen: boolean;
}

export const ConsentWrapper: FC<ConsentWrapperProps> = ({ children, isOpen = false }) => {
	const { classes: styles } = useStyles();

	return isOpen ? (
		<div className={styles.container} data-cy="consent-wrapper" data-nosnippet>
			<Content>
				<div className={styles.content}>{children}</div>
			</Content>
		</div>
	) : null;
};
