import { Booking } from '@vakantiesnl/types';

type TravellersType = {
	pax: number;
	adult: number;
	child: number;
	baby: number;
};

export const mapTravellers = (input: Booking.PersonItems): TravellersType => {
	const travellers = {
		pax: 0,
		adult: 0,
		child: 0,
		baby: 0,
	};

	input.forEach((t) => {
		travellers.pax++;
		if (t.ageType == 'ADULT') {
			travellers.adult++;
		}
		if (t.ageType == 'CHILD') {
			travellers.child++;
		}
		if (t.ageType == 'BABY') {
			travellers.baby++;
		}
	});

	return travellers;
};
