import { FeatureToggle } from '@vakantiesnl/services/src/hooks/rest/feature-toggle';
import { IVariant } from 'unleash-proxy-client';

export type ActiveFeaturesInCookies =
	| {
			/* Name of the active feature toggle/experiment */
			name: string;
			/* Name of the feature toggle's chosen and active variant */
			variant: string | undefined;
	  }[]
	| [];

/** Format the active/enabled experiments/feature toggles into the required cookie format */
export const featureTogglesFormatter = (
	activeFeatureToggles: FeatureToggle | Record<string, never>,
): ActiveFeaturesInCookies => {
	return Object.keys(activeFeatureToggles)
		.filter((expName) => activeFeatureToggles[expName as keyof FeatureToggle].isEnabled)
		.filter((expName) => {
			/** Filter out feature toggles, so the non experiment ones. */
			const currentVariant: IVariant | undefined = activeFeatureToggles[expName as keyof FeatureToggle].variant;
			return currentVariant?.enabled;
		})
		.map((expName) => {
			const currentVariant: IVariant | undefined = activeFeatureToggles[expName as keyof FeatureToggle].variant;

			return {
				name: expName,
				variant: currentVariant?.name,
			};
		});
};
